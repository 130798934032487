import React from "react";
import { PlaylistSongListItem } from "./playlistSongListItem";
import { Playlist } from "common";

export function PlaylistSongList({
  songs,
  title,
  listener,
}: {
  songs: Playlist["songs"];
  title: Playlist["title"];
  listener: Playlist["listener"];
}) {
  return (
    <ul className="divide-y divide-slate-700/50">
      {songs.map((song) => (
        <PlaylistSongListItem
          key={song.songId}
          song={song}
          playlistId={title}
          playlistListener={listener}
        />
      ))}
    </ul>
  );
}
