import {
  PlayingSong,
  QueueSong,
  QueuedSong,
  ReorderSong,
  SearchSong,
  SkipSong,
  Song,
  UnqueueSong,
  ToggleSongLike,
  Shuffle,
  ToggleTag,
  StuenMessage,
  SearchSongResult,
  CurrentQueue,
  CurrentSong,
  SongLikes,
  RemoveFromAutoPlay,
} from "common";
import { ws } from "./api";
import { isSearcingSongs, songs } from "../state/songSearch";
import { useMockApi, userName } from "../state/global";
import { queue } from "../state/queue";
import {
  currentSong,
  currentSongStart,
  currentSongUniqueId,
  currentSongUrl,
} from "../state/player";
import { songLikes } from "../state/songLikes";
import { addSongToPlaylist, removeSongFromPlaylist } from "./playlist";

export const handleSongEvents = (socketMessage: StuenMessage) => {
  if (socketMessage instanceof SearchSongResult) {
    isSearcingSongs.value = false;
    songs.value = [...socketMessage.result];
  }
  if (socketMessage instanceof CurrentQueue) {
    queue.value = [...socketMessage.songs];
  }
  if (socketMessage instanceof CurrentSong) {
    currentSongUniqueId.value = crypto.randomUUID();
    currentSong.value = socketMessage.song || null;
    currentSongUrl.value = socketMessage.song?.songId || null;
    currentSongStart.value = socketMessage.song?.position || 0;
  }
  if (socketMessage instanceof SongLikes) {
    songLikes.value = socketMessage.listeners;
  }
};

export const searchSong = (message: string) => {
  if (useMockApi.value) {
    songs.value = [
      {
        type: 0,
        title:
          "Passenger - Let Her Go (Feat. Ed Sheeran - Anniversary Edition) [Official Video]",
        songId: "HTcL9WkB_wg",
        image: `http://i3.ytimg.com/vi/HTcL9WkB_wg/hqdefault.jpg`,
        inAutoPlay: true,
      },
      {
        type: 0,
        title:
          "Cozy Fireplace 🔥 A peaceful night next to a soothing fireplace to cure insomnia and relieve stress",
        songId: "33sq6cZ_WUM",
        image: `http://i3.ytimg.com/vi/33sq6cZ_WUM/hqdefault.jpg`,
        inAutoPlay: false,
      },
    ];
  } else {
    isSearcingSongs.value = true;
    ws?.send(JSON.stringify(new SearchSong(message)));
  }
};

export const queueSong = (song: Song) => {
  if (useMockApi.value) {
    if (!currentSong.value) {
      currentSongUniqueId.value = crypto.randomUUID();
      currentSong.value = {
        ...song,
        length: 100,
        position: 0,
        listener: userName.value || "Test",
        tags: [],
      };
      currentSongUrl.value = song.songId;
      currentSongStart.value = 0;
    } else {
      queue.value = [
        ...queue.value,
        {
          ...song,
          listener: userName.value || "Test",
        },
      ];
    }
  } else {
    ws?.send(JSON.stringify(new QueueSong(song)));
  }
};

export const queueSongs = (songs: Song[]) => {
  songs.forEach((song) => {
    queueSong(song);
  });
};

export const unqueueSong = (position: number, all?: boolean) => {
  if (useMockApi.value) {
    queue.value = queue.value.filter((_, index) => index !== position);
  } else {
    ws?.send(JSON.stringify(new UnqueueSong(position, all)));
  }
};

export const skipSong = () => {
  if (useMockApi.value) {
    const firstSongInQueue = queue.value[0];
    if (firstSongInQueue) {
      queue.value = queue.value.filter((_, index) => index !== 0);
      currentSongUniqueId.value = crypto.randomUUID();
      currentSong.value = {
        ...firstSongInQueue,
        length: 100,
        position: 0,
        listener: userName.value || "Test",
        tags: [],
      };
      currentSongUrl.value = firstSongInQueue.songId;
      currentSongStart.value = 0;
    } else {
      currentSong.value = null;
      currentSongUrl.value = null;
      currentSongUniqueId.value = null;
      currentSongStart.value = 0;
    }
  } else {
    ws?.send(JSON.stringify(new SkipSong()));
  }
};

export const shuffle = () => {
  if (useMockApi.value) {
    console.log("Mock not implemented");
  } else {
    ws?.send(JSON.stringify(new Shuffle()));
  }
};

export const removeSongFromAutoPlay = () => {
  if (useMockApi.value) {
    console.log("Mock not implemented");
  } else {
    ws?.send(JSON.stringify(new RemoveFromAutoPlay()));
  }
};

export const toggleLikeSong = () => {
  if (useMockApi.value) {
    songLikes.value = songLikes.value.includes(userName.value || "Test")
      ? songLikes.value.filter(
          (listener) => listener !== userName.value || "Test",
        )
      : [...songLikes.value, userName.value || "Test"];
  } else {
    ws?.send(JSON.stringify(new ToggleSongLike()));
  }
};

export const reorderSong = (positionToMove: number, placePosition: number) => {
  if (useMockApi.value) {
  } else {
    ws?.send(JSON.stringify(new ReorderSong(positionToMove, placePosition)));
  }
};

export const addSongToFavorites = (song: Song | PlayingSong | QueuedSong) => {
  addSongToPlaylist(song, "favorites");
};

export const removeSongFromFavorites = (
  song: Song | PlayingSong | QueuedSong,
) => {
  removeSongFromPlaylist(song, "favorites");
};

export const toggleTag = (song: Song, tag: string) => {
  if (useMockApi.value) {
  } else {
    ws?.send(JSON.stringify(new ToggleTag(song, tag)));
  }
};
