import React from "react";
import {
  skipSong,
  shuffle,
  removeSongFromAutoPlay,
  unqueueSong,
} from "../../../api/song";
import { Popover, PopoverContent, PopoverTrigger } from "../../popover";
import { currentSong } from "../../../state/player";
import { CurrentSongPlaylistPicker } from "./currentSongPlaylistPicker";
import { CurrentSongTagPicker } from "./currentSongTagPicker";

export function CurrentSongMoreButton() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [expandedSubMenu, setExpandedSubMenu] = React.useState<
    null | "tags" | "playlists"
  >(null);

  const handleSkipSong = () => {
    skipSong();
    setIsOpen(false);
  };

  const handleShuffle = () => {
    shuffle();
    setIsOpen(false);
  };

  const handleRemoveSongFromAutoPlay = () => {
    removeSongFromAutoPlay();
    setIsOpen(false);
  };

  const handlePopoverOpenChanged = (open: boolean) => {
    setIsOpen(open);
  };

  const handleChangeExpandedSubMenu = (
    subMenu: "tags" | "playlists" | null,
  ) => {
    setExpandedSubMenu(subMenu);
  };

  const handleClearMyQueue = () => {
    unqueueSong(0, true);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block">
      <Popover
        open={isOpen}
        onOpenChange={handlePopoverOpenChanged}
        placement="bottom-end"
      >
        <PopoverTrigger
          onClick={() => setIsOpen((v) => !v)}
          className="bg-slate-700 rounded-md py-2 px-1 whitespace-nowrap text-sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 inline-block -mt-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
            />
          </svg>
        </PopoverTrigger>
        <PopoverContent className="border border-slate-600 rounded-lg w-60 bg-slate-700  text-white z-20 max-h-full overflow-y-auto">
          <button
            onClick={handleSkipSong}
            type="button"
            className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600 hover:text-white focus:text-white whitespace-nowrap"
          >
            Skip song
          </button>
          {currentSong.value && (
            <>
              <CurrentSongPlaylistPicker
                isExpanded={expandedSubMenu === "playlists"}
                onChangeExpandedSubMenu={handleChangeExpandedSubMenu}
              />
              <CurrentSongTagPicker
                isExpanded={expandedSubMenu === "tags"}
                onChangeExpandedSubMenu={handleChangeExpandedSubMenu}
              />
            </>
          )}
          <hr className="border-slate-600" />
          <div className="uppercase text-[10px] text-slate-400 pl-4 font-semibold mt-2 -mb-0.5">
            My queue
          </div>
          <button
            onClick={handleShuffle}
            type="button"
            className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600 hover:text-white focus:text-white whitespace-nowrap"
          >
            Shuffle my songs
          </button>
          <button
            onClick={handleClearMyQueue}
            type="button"
            className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600 hover:text-white focus:text-white whitespace-nowrap"
          >
            Clear my songs
          </button>
          <hr className="border-slate-600" />
          <button
            onClick={handleRemoveSongFromAutoPlay}
            type="button"
            className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600 hover:text-white focus:text-white whitespace-nowrap"
          >
            Remove song from Auto Play
          </button>
        </PopoverContent>
      </Popover>
    </div>
  );
}
