import React, { useRef, useEffect } from "react";
import { messages } from "../../state/chat";
import { ChatInput } from "./chatInput";
import { ChatMessage } from "./chatMessage";

export function Chat() {
  const messageListBottomRef = useRef<HTMLDivElement>(null);
  const chatInputRef = useRef<HTMLDivElement>(null);
  const [chatHeight, setChatHeight] = React.useState(58);

  // Resize observer to adjust chat height when chat input height changes
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === chatInputRef.current) {
          setChatHeight(entry.contentRect.height);
        }
      }
    });

    if (chatInputRef.current) {
      resizeObserver.observe(chatInputRef.current);
    }

    return () => {
      if (chatInputRef.current) {
        resizeObserver.unobserve(chatInputRef.current);
      }
    };
  }, [chatInputRef.current]);

  const handleScrollToBottom = () => {
    messageListBottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  useEffect(() => {
    if (messages.value?.length) {
      handleScrollToBottom();
    }
  }, [messages.value?.length, messageListBottomRef.current, chatHeight]);

  return (
    <div className="h-[372px] md:h-full overflow-hidden relative">
      <div
        className="overflow-y-auto"
        style={{ height: `calc(100% - ${chatHeight}px)` }}
      >
        <ul className="divide-y divide-gray-700">
          {messages.value.map((message) => (
            <ChatMessage key={message.id} message={message} />
          ))}
        </ul>
        {messages.value.length > 0 && (
          <button
            onClick={() => {
              messages.value = [];
            }}
            className="text-xs absolute left-[50%] text-slate-500 hover:text-white hover:underline -translate-x-1/2"
            style={{ bottom: chatHeight + 10 + "px" }}
          >
            Clear chat
          </button>
        )}
        <div ref={messageListBottomRef} />
      </div>
      <div ref={chatInputRef}>
        <ChatInput />
      </div>
    </div>
  );
}
