import React from "react";
import { Playlist, Song } from "common";
import {
  PlusIcon,
  VideoCameraIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { queueSong } from "../../api/song";
import { openSongPreview } from "../../state/songPreview";
import { removeSongFromPlaylist } from "../../api/playlist";
import { userName } from "../../state/global";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { PlaylistSongPlaylistPicker } from "./playlistSongPlaylistPicker";

export function PlaylistSongListItem({
  song,
  playlistId,
  playlistListener,
}: {
  song: Song;
  playlistId: Playlist["title"];
  playlistListener: Playlist["listener"];
}) {
  const ref = React.useRef<HTMLLIElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <li
      ref={ref}
      className="flex justify-between gap-x-6 py-1.5 group relative pl-2 items-center hover:bg-white/5"
    >
      <div className="flex min-w-0 gap-x-2 items-center w-full">
        <img
          className="h-12 w-[64px] flex-none drop-shadow-lg shadow-lg bg-gray-800"
          src={song.image}
          alt=""
        />
        <div className="w-full flex-auto">
          <p className="text-sm font-semibold leading-6 text-white line-clamp-2">
            {song.title}
          </p>
        </div>
        <div className="text-right whitespace-nowrap flex items-center">
          <button
            className="bg-green-700 hover:bg-green-600 rounded-full p-1 mr-1"
            onClick={() => queueSong(song)}
          >
            <PlusIcon className="h-4 w-4" aria-hidden="true" />
          </button>
          <Popover
            open={isOpen}
            onOpenChange={setIsOpen}
            placement="bottom-end"
          >
            <PopoverTrigger
              onClick={() => setIsOpen((v) => !v)}
              className="bg-slate-600 hover:bg-slate-500 rounded-full whitespace-nowrap mr-2 items-center justify-center relative h-6 w-6"
            >
              <EllipsisHorizontalIcon className="w-4 h-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            </PopoverTrigger>
            <PopoverContent className="border border-slate-600 rounded-lg bg-slate-700  text-white w-52 p-1 max-h-full overflow-y-auto">
              <button
                onClick={() => openSongPreview(song)}
                type="button"
                className="relative inline-flex gap-x-2 items-center w-full px-1 py-1 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600 hover:text-white focus:text-white whitespace-nowrap"
              >
                <div className="bg-sky-600 rounded-md p-1">
                  <VideoCameraIcon className="h-4 w-4" aria-hidden="true" />{" "}
                </div>
                Preview
              </button>
              <PlaylistSongPlaylistPicker song={song} />
              {playlistListener === userName.value && (
                <button
                  onClick={() => removeSongFromPlaylist(song, playlistId)}
                  type="button"
                  className="relative inline-flex gap-x-2 items-center w-full p-1 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600 hover:text-white focus:text-white whitespace-nowrap"
                >
                  <div className="bg-red-600 rounded-md p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                  Delete
                </button>
              )}
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </li>
  );
}
