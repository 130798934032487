import React from "react";
import { ownPlaylists } from "../../state/playlists";
import { addSongToPlaylist, removeSongFromPlaylist } from "../../api/playlist";
import clsx from "clsx";
import type { Song } from "common";

export function PlaylistSongPlaylistPicker({ song }: { song: Song }) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleAddSongToPlaylist = (playlistTitle: string) => {
    if (!song) return;
    addSongToPlaylist(song, playlistTitle);
  };

  const handleRemoveSongFromPlaylist = (playlistTitle: string) => {
    if (!song) return;
    removeSongFromPlaylist(song, playlistTitle);
  };

  const handleToggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <button
        onClick={handleToggleExpanded}
        type="button"
        className={clsx(
          "relative inline-flex items-center gap-x-2 w-full py-1 pl-1 text-sm font-medium rounded-lg focus:z-10  hover:text-white hover:bg-slate-600 focus:text-white whitespace-nowrap justify-between",
          isExpanded && "bg-slate-600/70",
        )}
      >
        <div className="flex items-center gap-x-2">
          <div className="bg-yellow-600 rounded-md p-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
              />
            </svg>
          </div>
          Add to playlist
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={clsx("w-5 h-5", isExpanded && "transform rotate-90")}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
      {isExpanded && (
        <div className="py-1">
          {ownPlaylists.value.map((playlist) => {
            const isSongInPlaylist = playlist.songs.some(
              (s) => s.songId === song?.songId,
            );
            return (
              <button
                key={playlist.title}
                onClick={() =>
                  isSongInPlaylist
                    ? handleRemoveSongFromPlaylist(playlist.title)
                    : handleAddSongToPlaylist(playlist.title)
                }
                type="button"
                className="relative inline-flex items-center w-full px-2 py-1 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600/50 hover:text-white focus:text-white whitespace-nowrap uppercase"
              >
                <span className="flex items-center gap-2 max-w-full">
                  <div className="w-4 h-4 inline-block border border-slate-500 rounded flex-shrink-0">
                    {isSongInPlaylist && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 inline-block text-white -mt-2 -ml-[1px]"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>
                    )}
                  </div>

                  <span className="truncate">{playlist.title}</span>
                </span>
              </button>
            );
          })}
          <hr className="border-slate-600 mt-1" />
        </div>
      )}
    </>
  );
}
